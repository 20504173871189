<template>
  <div class="job-board" v-if="!re_render_the_html_content">
    <div class="job-board-detail-component mb-3">
      <div class="container">
        <div class="row logo-summary mt-3">
          <div
            class="
              col-lg-4 col-sm-12 col-md-12
              d-flex
              justify-content-center
              align-items-center
              p-0
            "
          >
            <div class="d-flex flex-column align-items-center">
              <div class="text-center" style="margin-top: 16px">
                <img
                  :src="fileSource?.logo"
                  class="rounded-circle"
                  style="width: 65% !important"
                  @error="
                    $event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:40%';
                  "
                  alt="logo"
                />
              </div>
              <div class="my-3">
                <h6 class="text-primary text-center">{{ organisation }}</h6>
                <div class="location text-primary">
                  <p>
                    <i class="fas fa fa-map-marker-alt"></i>&nbsp;
                    <span style="color: #00000099">
                      <span v-if="displayLocation">{{ displayLocation }}</span>
                      <span v-else>{{ location }}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left">{{ title }}</p>
            </div>
            <div class="d-flex">
              <div class="detail-group">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Type</div>
                  <div class="value">{{ candidateType }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Level</div>
                  <p
                    class="value"
                    v-if="isTextWrapped(candidateLevel, 50)"
                    v-c-tooltip="{
                      content: candidateLevel,
                    }"
                  >
                    {{ candidateLevel.substring(0, 50) }}...
                  </p>
                  <p class="value" v-else>{{ candidateLevel }}</p>
                </div>
              </div>

              <div class="detail-group text-left">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Job Reference</div>
                  <div class="value">{{ jobReference }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Contract</div>
                  <div class="value">{{ contract }}</div>
                </div>
              </div>

              <div class="detail-group">
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Date Posted</div>
                  <div class="value">{{ date_created }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Expires on</div>
                  <div class="value">
                    {{ expiryDate }} |
                    <span style="font-weight: 500">{{ expiryDays }}</span>
                  </div>
                </div>
              </div>
              <social-sharing
                v-if="false"
                :url="url"
                :title="title"
                :description="jobReference"
                inline-template
              >
                <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
                  <template #toggler-content>
                    <div class="d-flex align-items-center text">
                      <CIcon name="cil-share-alt" class="color-primary" />
                    </div>
                  </template>
                  <CDropdownItem>
                    <network network="email">
                      <i class="fa fa-envelope"></i> Email
                    </network>
                  </CDropdownItem>
                  <div>
                    <CDropdownItem>
                      <network network="facebook">
                        <i class="fab fa-facebook-square"></i> Facebook
                      </network>
                    </CDropdownItem>
                  </div>
                </CDropdown>
              </social-sharing>
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="detail-group" v-if="isOwnJob">
                <CButton
                  v-if="!getApplicationStatus"
                  type="button"
                  class="btn btn-primary mb-3"
                  :name="`apply-job-${jobReference}`"
                  :disabled="
                    disableJobApplyBtn &&
                    jobReference == getSelectedJobBoard.display_uid
                  "
                  @click="$parent.onApplyJob(jobID)"
                >
                  Apply Now
                </CButton>
                <CButton
                  v-else
                  type="button"
                  class="btn btn-primary mb-3"
                  :name="`withdraw-job-${jobReference}`"
                  @click="$parent.onWithdrawJob()"
                >
                  Withdraw
                </CButton>
              </div>
              <div class="detail-group" v-if="!isOwnJob">
                <CButton
                  type="button"
                  class="btn btn-primary mb-3"
                  :name="`apply-${jobReference}`"
                  :disabled="
                    disableJobApplyBtn &&
                    jobReference == getSelectedJobBoard.display_uid
                  "
                  @click="redirectToSharedJobBoard"
                >
                  Apply Now
                </CButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 100%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="job-image"
        />
      </div>

      <div class="pr-2 pl-3 mt-3" v-html="jobDescription"></div>
      <div
        class="pl-3 pr-3 mt-2"
        v-for="(item, index) in jobDocuments"
        :key="index"
      >
        For Additional Information
        <a @click="fileDownload(item)" class="cursor-pointer text-primary">
          {{ item.library_name || "Click here" }}
        </a>
      </div>
    </div>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="false"
    />
    <PreviewPreScreening
      v-if="previewPreScreeningModal.isShowPopup"
      :isShowPopup="previewPreScreeningModal.isShowPopup"
      :jobInfo="previewPreScreeningModal.jobInfo"
      :preScreeningQuestions="previewPreScreeningModal.preScreeningQuestions"
      :modalTitle="previewPreScreeningModal.modalTitle"
      @modalCallBack="previewPreScreeningModalCallBack"
    />
  </div>
</template>

<script>
import m from "moment";
import { mapGetters, mapActions } from "vuex";
import { LOGIN_URL } from "../../helpers/helper";
import Modal from "@/components/reusable/Modal";
import PreviewPreScreening from "@/components/reusable/PreviewPreScreening";
import { getLocalOrgDetail, CONSTANT as CONST, appendBaseUrlWithLink } from "@/helpers/helper";

export default {
  components: {
    Modal,
    PreviewPreScreening,
  },
  data() {
    return {
      successModal: false,
      urlFullPath: "",
      modalMessage: "",
      isApplyingToJob: false,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      disableJobApplyBtn: false,
      needToRegisterUser: false,
      re_render_the_html_content: false,
      previewPreScreeningModal: {
        isShowPopup: false,
        preScreeningQuestions: [],
        jobInfo: null,
        modalTitle:
          "Please complete the following questions to assist your application",
      },
      appendBaseUrlWithLink
    };
  },
  created() {
    const {
      fullPath,
      params: { id },
    } = this.$route;
    this.urlFullPath = fullPath;
  },
  computed: {
    ...mapGetters([
      "getSelectedJobBoard",
      "getRoleScopes",
      "getApplicationStatus",
      "getSelectedJobBenefitsAndAdvertDesc",
      "getOrgID",
      "getCandidateEmail",
      "getLocationIDFromAccessToken",
      "preScreeningQuestions",
      "candidateProfile",
      "getCustomerId",
      "getOrgIDFromAccessToken",
      "setNewOrgAcronym",
      "isJobSupplierFromAccessToken",
      "getOrgTypeIDFromAccessToken",
      "isSuperCustomer",
    ]),
    isPublished() {
      return this.getSelectedJobBoard?.published;
    },
    jobStatus() {
      return this.getSelectedJobBoard?.job_status?.status || "";
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    candidateType() {
      return this.getSelectedJobBoard?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      return this.getSelectedJobBoard?.level_names?.join(", ") || "--";
    },
    featured() {
      return this.getSelectedJobBoard?.featured ? "Yes" : "No";
    },
    isParentReBranding() {
      return this.getSelectedJobBoard?.parent_org_info_required;
    },
    isCandidateOrPublicBoard() {
      return (
        !this.getRoleScopes.length ||
        ["system_candidate", "customer_candidate"].includes(
          this.getRoleScopes[0]
        )
      );
    },
    organisation() {
      if (!this.isOwnJob) {
        return this.getSelectedJobBoard?.organisation_name || "";
      }
      if (
        this.isParentReBranding &&
        this.isCandidateOrPublicBoard &&
        this.getSelectedJobBoard?.parent_org_info?.length
      ) {
        return this.getSelectedJobBoard?.parent_org_info[0]?.name || "--";
      }
      return this.getSelectedJobBoard?.organisation?.name || "--";
    },
    jobDescription() {
      return this.getSelectedJobBenefitsAndAdvertDesc?.advert_description || "";
    },
    jobDocuments() {
      if (this.getSelectedJobBenefitsAndAdvertDesc?.advert_documents?.length) {
        return this.getSelectedJobBenefitsAndAdvertDesc?.advert_documents?.map(
          (val) => ({
            document_name:
              val?.document_name ||
              val?.document_library?.organisation_document?.document_name,
            url:
              val?.link_to_doc ||
              val?.document_library?.organisation_document?.link_to_doc,
            library_name: val?.document_library?.library_name,
          })
        );
      }
      return false;
    },
    jobPicture() {
      return this.getSelectedJobBoard?.job_picture?.link_to_doc || "";
    },
    location() {
      return this.getSelectedJobBoard?.location?.location || "--";
    },
    jobReference() {
      return `${this.getSelectedJobBoard?.display_uid}` || "--";
    },
    jobID() {
      return this.getSelectedJobBoard?.job_id || "--";
    },
    title() {
      return this.getSelectedJobBoard?.job_title || "";
    },
    status() {
      return this.getSelectedJobBoard?.status?.status || "";
    },
    appliedOn() {
      let appliedOn = this.getSelectedJobBoard?.created_on || "";
      return m(appliedOn).format("YYYY-MM-DD");
    },
    contract() {
      if (this.getSelectedJobBoard) {
        const { permanent_work, temporary_work } = this.getSelectedJobBoard;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
        else return "--";
      } else {
        return "--";
      }
    },
    expiryDate() {
      return this.formatDate(this.getSelectedJobBoard?.expiry_review_date);
    },
    expiryDays() {
      let days = 0;
      let reviewDate = this.getSelectedJobBoard?.sharing_end_date
        ? this.getSelectedJobBoard?.sharing_end_date
        : this.getSelectedJobBoard?.expiry_review_date;
      if (reviewDate) {
        let diff = m(reviewDate).diff(m(), "days");
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    date_created() {
      const dateField =
        this.getSelectedJobBoard?.published_on ||
        this.getSelectedJobBoard?.created_on;
      return this.formatDate(dateField);
    },
    url() {
      return window?.location?.href;
    },
    hospital() {
      return this.getSelectedJobBoard?.hospital || "--";
    },
    parent_organisation() {
      let job = this.getSelectedJobBoard;
      return job?.organisation?.parent_organisation?.[0];
    },
    fileSource() {
      let job = this.getSelectedJobBoard;
      if (
        this.isParentReBranding &&
        this.isCandidateOrPublicBoard &&
        job?.parent_org_info?.length
      ) {
        return {
          logo: appendBaseUrlWithLink(job?.parent_org_info[0]?.logo_url) || "",
          image: appendBaseUrlWithLink(job?.parent_org_info[0]?.default_job_banner_url) || "",
        };
      }
      return {
        logo: appendBaseUrlWithLink(job?.organisation?.logo_url) || "",
        image: this.jobPicture
          ? appendBaseUrlWithLink(this.jobPicture)
          : appendBaseUrlWithLink(job?.organisation?.default_job_banner_url) || "",
      };
    },
    favouriteID() {
      return this.getSelectedJobBoard?.ids?.favourite_id || null;
    },
    appliedID() {
      return this.getSelectedJobBoard?.ids?.applied_id || null;
    },
    isDraftOrPending() {
      let draft_or_pending_job_status = [27, 28];
      return draft_or_pending_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      );
    },
    isPendingJob() {
      let pending_job_status = [28];
      return pending_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      );
    },
    isActiveJob() {
      let active_job_status = [29];
      return active_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      );
    },
    isInActiveJob() {
      let inactive_job_status = [30];
      return inactive_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      );
    },
    isArchiveJob() {
      let archive_job_status = [31];
      return archive_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      );
    },
    isOwnJob() {
      if (this.getSelectedJobBoard?.is_own_job === null) {
        return true;
      }
      return this.getSelectedJobBoard?.is_own_job;
    },
    is_rejected() {
      return this.getSelectedJobBoard?.is_accepted === false || false;
    },
    isTFWPublicJobBoard() {
      const { name } = this.$route;
      return ["TFW Job Board", "TFW Job Board Detail"].includes(name) || false;
    },
    displayLocation() {
      return this.getSelectedJobBoard?.display_location;
    },
  },
  methods: {
    ...mapActions([
      "applyJob",
      "cancelJob",
      "showToast",
      "withdrawJob",
      "addPreferredJob",
      "deletePreferredJob",
      "getRegisteredHospitalsByEmail",
      "registerCommonCandidateWithoutPwd",
      "downloadDocument",
      "getPreScreeningQuestions",
      "createCandidateTransaction",
      "showToast",
      "deleteCandidateTransaction",
      "fetchAcronymByOrgDetail",
    ]),
    onApplyJob() {
      if (this.getRoleScopes.length) {
        this.isApplyingToJob = true;
        this.modalMessage = "";
        const orgID = this.getOrgID ?? null;
        if (
          (this.getSelectedJobBoard?.organisation_uid !== orgID || orgID === null) && this.getSelectedJobBoard?.customer_uid !== this.getCustomerId
          // && ![
          //   CONST.ORG_TYPES["agency"],
          //   CONST.ORG_TYPES["partner_hospital"],
          // ].includes(
          //   this.getSelectedJobBoard?.organisation?.organisation_type_id
          // )
        ) {
          let checkCandidateExists = {
            email: this.getCandidateEmail,
            customer_uid: this.getSelectedJobBoard?.customer_uid,
            organisation_uid: this.getSelectedJobBoard?.organisation_uid,
          };
          this.getRegisteredHospitalsByEmail(checkCandidateExists).then(
            (res) => {
              const errMsg =  res?.response?.data?.detail?.message ||  res?.response?.data?.detail;
              if (
                res?.response?.status === 400 &&
                errMsg?.includes("Already registered")
              ) {
                this.modalMessage =
                  "Are you sure you want to apply for this job?";
                this.previewPreScreeningModal.jobInfo =
                  this.getSelectedJobBoard;
                this.$store.commit("RESET_PRE_SCREENING_QUESTIONS");
              } else {
                this.modalMessage = `Please confirm you wish to share your details to apply for this job.`;
                this.needToRegisterUser = true;
              }
            }
          );
        } else {
          this.modalMessage = "Are you sure you want to apply for this job?";
          this.previewPreScreeningModal.jobInfo = this.getSelectedJobBoard;
          this.$store.commit("RESET_PRE_SCREENING_QUESTIONS");
        }
      } else {
        this.modalMessage = "Please Login/Register to apply for the jobs";
      }
      this.successModal = true;
    },
    onWithdrawJob() {
      this.successModal = true;
      this.modalMessage = "Are you sure you want to withdraw from this job?";
    },
    async onShow(e, value) {
      if (value.target.textContent === " OK ") {
        this.disableJobApplyBtn = true;
        if (this.getRoleScopes.length) {
          if (this.isApplyingToJob) {
            if (this.getSelectedJobBoard.has_pre_screening_questions) {
              const { job_id } = this.getSelectedJobBoard;
              await this.getPreScreeningQuestions(job_id);
              this.previewPreScreeningModal.preScreeningQuestions =
                this.preScreeningQuestions;
              this.previewPreScreeningModal.jobInfo = this.getSelectedJobBoard;
              this.previewPreScreeningModal.isShowPopup = true;
            } else {
              this.applyingJob();
            }
          } else {
            this.withdrawJob(this.getSelectedJobBoard).then((res) => {
              const { job_id } = this.getSelectedJobBoard;
              const { candidate_uid } = this.candidateProfile;
              this.deleteCandidateTransaction({ job_id, candidate_uid });
              this.disableJobApplyBtn = false;
            });
          }
        } else {
          this.isApplyingToJob = false;
          let {
            customer_uid,
            organisation_uid,
            location_id,
            parent_org_info_required,
            parent_org_info,
          } = this.getSelectedJobBoard;
          if (parent_org_info_required) {
            location_id = parent_org_info[0]?.location_id;
            organisation_uid = parent_org_info[0]?.organisation_id;
          }
          if (getLocalOrgDetail().organisation_id == organisation_uid)
            this.$router.push({ path: LOGIN_URL() });
          else {
            let appendAction = this.fetchAcronymByOrgDetail({
              customer_uid,
              organisation_uid,
              location_id,
            });
            Promise.all([appendAction]).then((res) => {
              if (this.setNewOrgAcronym) {
                window.location.href = `${window.location.origin}/${this.setNewOrgAcronym}`;
              } else {
                this.$router.push({ path: LOGIN_URL() });
              }
            });
          }
        }
       }
    },
    applyingJob() {
      if (this.needToRegisterUser) {
        this.registerCommonCand();
      } else {
        this.postApplyJob();
      }
    },
    previewPreScreeningModalCallBack(action, data) {
      if (action && data) {
        const payload = data.map((v) => {
          return {
            question_id: v?.question_id,
            customer_uid: this.getCustomerId,
            organisation_id: this.getOrgIDFromAccessToken,
            ...v.answer,
          };
        });
        const { job_id } = this.previewPreScreeningModal.jobInfo;
        const { candidate_uid } = this.candidateProfile;
        this.createCandidateTransaction({
          job_id,
          candidate_uid,
          payload,
        }).then((res) => {
          if (res.status == 200) {
            this.showToast({
              class: "bg-success text-white",
              message: "Pre-Screening response Completed and Saved!",
            });
            this.applyingJob();
          }
          this.previewPreScreeningModal.isShowPopup = false;
          this.previewPreScreeningModal.jobInfo = null;
          this.previewPreScreeningModal.preScreeningQuestions = [];
        });
      } else {
        this.disableJobApplyBtn = false;
        this.isApplyingToJob = false;
        this.previewPreScreeningModal.isShowPopup = false;
        this.previewPreScreeningModal.jobInfo = null;
        this.previewPreScreeningModal.preScreeningQuestions = [];
      }
    },
    postApplyJob() {
      this.applyJob(this.getSelectedJobBoard).then((res) => {
        this.disableJobApplyBtn = false;
        this.isApplyingToJob = false;
      });
    },
    registerCommonCand() {
      let payload = {
        email: this.getCandidateEmail,
        customer_uid: this.getSelectedJobBoard?.customer_uid,
        organisation_id: this.getSelectedJobBoard?.organisation_uid,
        location_id: this.getLocationIDFromAccessToken,
      };
      this.registerCommonCandidateWithoutPwd(payload).then(() => {
        this.postApplyJob();
        this.needToRegisterUser = false;
      });
    },
    numberWithCommas(number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    favouriteJob(favourite_id) {
      let { job_id } = this.getSelectedJobBoard;
      const preferred_type = "favourite";
      if (!favourite_id) {
        this.addPreferredJob({ job_id, preferred_type });
      } else {
        this.deletePreferredJob({
          job_id,
          preferred_id: favourite_id,
          preferred_type,
        });
      }
    },
    redirectToSharedJobBoard() {
      this.confirmModal.modalContent = "It will take you to the new site!";
      this.confirmModal.isShowPopup = true;
    },
    modalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        let url = this.getSelectedJobBoard?.public_job_board_link;
        let regex =
          /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (regex.test(url)) {
          window.open(url);
        }
      }
      this.confirmModal.isShowPopup = false;
    },
    formatDate(date) {
      return date ? m(date).format("DD MMM YYYY") : "--";
    },
    isTextWrapped(data, length) {
      return data?.length > length || false;
    },
    fileDownload(data) {
      let { url, document_name } = data;
      url = url.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
    },
  },
  watch: {
    // To re-render the html content which updates tooltip properly
    candidateLevel() {
      this.re_render_the_html_content = true;
      setInterval(() => {
        this.re_render_the_html_content = false;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}
</style>
