<template>
  <div class="job-board" v-if="!re_render_the_html_content">
    <div class="job-board-detail-component">
      <div class="container">
        <div class="row logo-summary">
          <div
            class="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center p-0 leftbox"
          >
            <div class="d-flex flex-column align-items-center">
              <CRow class="justify-content-center" style="margin-top: 16px">
                <img
                  :src="fileSource.logo"
                  class="rounded-circle ratio-1"
                  style="width: 120px !important"
                  @error="
                    $event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:40% !important';
                  "
                  alt="logo"
                />
                <span
                  class="text-danger parent-info"
                  v-if="
                    !isCandidateOrPublicBoard &&
                    isParentReBranding &&
                    !isJobSupplierFromAccessToken
                  "
                  style="color: danger"
                >
                  <em
                    class="parent-branding-dot"
                    v-c-tooltip="{
                      content: 'Parent Branding Enabled',
                      appendToBody: true,
                    }"
                  ></em>
                </span>
              </CRow>
              <div class="my-3">
                <h6 class="text-primary text-center">{{ organisation }}</h6>
                <div class="location text-primary">
                  <p>
                    <em class="fas fa fa-map-marker-alt"></em>&nbsp;
                    <span style="color: #00000099">
                      <span v-if="displayLocation">{{ displayLocation }}</span>
                      <span v-else>{{ location }}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left d-flex align-items-center">
                <a
                  v-if="!isCandidateOrPublicBoard"
                  :href="`/job-list-preview/${jobID}`"
                  class="text-primary"
                >
                  {{ title }}
                </a>
                <span v-else class="text-primary">{{ title }}</span>
                <span
                  v-if="
                    isAdmins &&
                    ![Role.systemAdmin].includes(currentUserRole) &&
                    !isJobSupplierFromAccessToken
                  "
                  @click="onCloneJob()"
                  style="cursor: pointer"
                  class="ml-2"
                >
                  <em
                    class="fas fa-copy"
                    v-c-tooltip="{
                      content: `Clone Job`,
                      placement: `bottom`,
                    }"
                  ></em>
                </span>
                <span
                  v-if="
                    latestApprovalAudit &&
                    isAdmins &&
                    ![Role.systemAdmin].includes(currentUserRole)
                  "
                  @click="showApprovalComments()"
                  style="cursor: pointer"
                  class="ml-3"
                >
                  <em
                    class="fas fa-comments"
                    v-c-tooltip="{
                      content: `Comments`,
                      placement: `bottom`,
                    }"
                  ></em>
                </span>
                <span
                  v-if="isShowTFWPublish && isPublished && isAdmins"
                  class="ml-2"
                >
                  <span
                    v-if="publish_in_super_hospital"
                    class="tfw-icon-green"
                    v-c-tooltip="{
                      content:
                        'This Job was published on the Talentfind Marketplace Public Job Board.',
                      placement: 'left',
                    }"
                  ></span>
                  <span
                    v-else-if="isAdmins"
                    class="tfw-icon cursor-pointer"
                    v-c-tooltip="{
                      content:
                        'Publish the job into Talentfind Marketplace Public Job Board',
                      placement: 'left',
                    }"
                    @click="openTFWModal"
                  ></span>
                </span>
                <span
                  v-if="
                    isAdmins &&
                    ![Role.systemAdmin].includes(currentUserRole) &&
                    getPendingStatus
                  "
                  :class="`status-${getPendingStatus.class}`"
                  class="ml-3 title"
                  style="white-space: nowrap"
                >
                  <span>{{ getPendingStatus.content }}</span>
                </span>
              </p>
            </div>
            <div class="d-flex">
              <div
                class="detail-group"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Type</div>
                  <div class="value">{{ candidateType }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Level</div>
                  <div
                    class="value pr-3"
                    v-if="isTextWrapped(candidateLevel, 55)"
                    v-c-tooltip="{
                      content: candidateLevel,
                    }"
                  >
                    {{ candidateLevel.substring(0, 55) }}...
                  </div>
                  <div class="value pr-2" v-else>{{ candidateLevel }}</div>
                </div>
              </div>

              <div
                class="detail-group text-left"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Job Reference</div>
                  <div v-if="!isCandidateOrPublicBoard" class="value">
                    <a
                      :href="`/job-list-preview/${jobID}`"
                      class="value"
                      style="font-weight: 500"
                      >{{ jobReference }}</a
                    >
                  </div>
                  <div v-else class="value">
                    <span class="value" style="font-weight: 500">
                      {{ jobReference }}
                    </span>
                  </div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Contract</div>
                  <div class="value">{{ contract }}</div>
                </div>
              </div>

              <div
                class="detail-group"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Date Posted</div>
                  <div class="value">{{ date_created }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Expires on</div>
                  <div class="value">
                    {{ expiryDate }} |
                    <span style="font-weight: 500">{{ expiryDays }}</span>
                  </div>
                </div>
              </div>
              <div class="detail-action" v-if="!isAdmins">
                <div class="detail-action-item">
                  <span
                    class="f-1_5 action position-relative multi-select-icon-div"
                    @click="favouriteJob(favouriteID)"
                  >
                    <em
                      v-show="favouriteID"
                      :class="`${
                        favouriteID !== null
                          ? 'text-primary fas fa-heart'
                          : 'far fa-heart'
                      }`"
                      v-c-tooltip="{
                        content: `Click to Remove favourite Job`,
                        placement: 'left',
                      }"
                    ></em>
                    <em
                      v-show="!favouriteID"
                      :class="`${
                        favouriteID !== null
                          ? 'text-primary fas fa-heart'
                          : 'far fa-heart'
                      }`"
                      v-c-tooltip="{
                        content: `Click to Add favourite Job`,
                        placement: 'left',
                      }"
                    ></em>
                  </span>
                </div>
                <div class="detail-action-item text-left">
                  <social-sharing
                    class="share"
                    v-if="true"
                    :url="url"
                    :title="shareTitle"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                    :twitter-user="sharing.twitterUser"
                    inline-template
                    v-c-tooltip="{
                      content: `Click to Share`,
                      placement: 'left',
                    }"
                  >
                    <CDropdown
                      class="nav-dropdown px-4"
                      add-menu-classes="pt-0"
                    >
                      <template #toggler-content>
                        <div class="d-flex align-items-center text">
                          <CIcon name="cil-share-alt" class="color-primary" />
                        </div>
                      </template>
                      <network network="email">
                        <CDropdownItem>
                          <em class="fa fa-envelope mr-1"></em> Email
                        </CDropdownItem>
                      </network>
                      <div>
                        <network network="facebook">
                          <CDropdownItem>
                            <em class="fab fa-facebook-square mr-1"></em>
                            Facebook
                          </CDropdownItem>
                        </network>
                      </div>
                    </CDropdown>
                  </social-sharing>
                </div>
              </div>
            </div>

            <div v-if="!isAdmins" class="d-flex flex-row-reverse mr-4 mt-4">
              <div class="detail-group">
                <CButton
                  v-if="!appliedID"
                  type="button"
                  :name="`apply-job-${jobReference}`"
                  :disabled="
                    disableJobApplyBtn &&
                    jobReference == getSelectedJobBoard.display_uid
                  "
                  class="btn btn-primary mb-3"
                  @click="onApplyJob()"
                >
                  Apply Now
                </CButton>
                <CButton
                  type="button"
                  class="btn btn-secondary text-primary mb-3"
                  v-else
                  :disabled="is_rejected"
                  :name="`withdraw-job-${jobReference}`"
                  @click="onWithdrawJob()"
                >
                  Withdraw
                </CButton>
                <CBadge
                  v-if="is_rejected && appliedID"
                  color="danger"
                  class="p-2"
                  >Application Unsuccessful</CBadge
                >
              </div>
            </div>
            <div v-else class="d-flex flex-row-reverse">
              <div class="detail-group">
                <div
                  v-if="
                    isAdmins && ![Role.systemAdmin].includes(currentUserRole)
                  "
                  class="detail-group-item"
                >
                  <button
                    v-if="
                      isDraftOrPending &&
                      this.getSelectedJobBoard.is_private_job
                    "
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="publishJob('direct_publish')"
                    :disabled="isJobSupplierFromAccessToken"
                  >
                    Publish Job
                  </button>
                  <button
                    v-if="
                      isDraftOrPending &&
                      !this.getSelectedJobBoard.is_private_job
                    "
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="publishJob('publish')"
                    :disabled="isJobSupplierFromAccessToken"
                  >
                    {{
                      latestApprovalAudit
                        ? "Preview & Republish"
                        : "Preview & Publish"
                    }}
                  </button>
                  <button
                    v-if="
                      Roles.includes(currentUserRole) &&
                      ![Role.systemAdmin].includes(currentUserRole) &&
                      isInActiveJob
                    "
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="makeJobDraft()"
                    :disabled="isJobSupplierFromAccessToken"
                  >
                    Make Draft
                  </button>
                </div>
              </div>
              <div class="detail-group">
                <div
                  class="action-group"
                  v-if="
                    Roles.includes(currentUserRole) &&
                    ![Role.systemAdmin].includes(currentUserRole)
                  "
                >
                  <button
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="onEditJob()"
                    :disabled="isJobSupplierFromAccessToken"
                  >
                    Edit Job
                  </button>
                </div>
              </div>
              <div
                class="detail-group"
                v-if="
                  Roles.includes(currentUserRole) &&
                  ![Role.systemAdmin].includes(currentUserRole) &&
                  isInActiveJob
                "
              >
                <button
                  type="button"
                  class="archive-button btn btn-primary mr-3"
                  @click="updateJobStatus('archive')"
                  :disabled="isJobSupplierFromAccessToken"
                >
                  Archive
                </button>
              </div>
              <div
                class="detail-group"
                v-if="
                  Roles.includes(currentUserRole) &&
                  ![Role.systemAdmin].includes(currentUserRole) &&
                  (isActiveJob || isDraftOrPending)
                "
              >
                <CDropdown style="width: fit-content" placement="bottom-start">
                  <template #toggler>
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      :disabled="isJobSupplierFromAccessToken"
                    >
                      Inactive / Archive
                    </button>
                  </template>
                  <a
                    v-if="isActiveJob || isDraftOrPending"
                    @click="updateJobStatus('inactive')"
                    class="dropdown-item"
                    style="cursor: pointer"
                  >
                    Inactive
                  </a>
                  <a
                    @click="updateJobStatus('archive')"
                    class="dropdown-item"
                    style="cursor: pointer"
                  >
                    Archive
                  </a>
                </CDropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 80%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="Job Image"
        />
      </div>
      <div class="pl-3 pr-3 mt-3" v-html="jobDescription"></div>
      <div
        class="pl-3 pr-3 mt-2"
        v-for="(item, index) in jobDocuments"
        :key="index"
      >
        For Additional Information
        <a @click="fileDownload(item)" class="cursor-pointer text-primary">
          {{ item.library_name || "Click here" }}
        </a>
      </div>
    </div>

    <CModal
      id="applyJobModal"
      title="NOTIFICATION"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
    >
      {{ this.modalMessage }}
    </CModal>

    <CModal
      id="Preview Job"
      centered:true
      :show.sync="publishModal"
      size="lg"
      color="primary"
    >
      <template #header-wrapper>
        <header class="modal-header" style="background: #dd3651; color: #fff">
          <h5
            class="modal-title px-3"
            style="font-size: 26px; font-weight: 300"
          >
            Preview Job
          </h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            ×
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <JobPreview :jobDetails="getSelectedJobBenefitsAndAdvertDesc">
        </JobPreview>
        <ValidationObserver
          ref="action_modal"
          v-slot="{ onPublish }"
          v-if="isSendForApprovalRequired"
        >
          <form id="action" @submit.prevent="onPublish()">
            <CRow class="m-2">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Alert Set For</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <DatePicker
                        name="publish_request_expiry_date"
                        :value="approval_audit.publish_request_expiry_date"
                        @input="handleApprovalAudit"
                        :showFromTmw="true"
                        :error="errors[0]"
                        format="DD-MM-YYYY"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="lbl_urgent" class="col-lg-12 col-md-12"
                    >Is Urgent?</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="publish_request_is_urgent"
                      :value="approval_audit.publish_request_is_urgent"
                      :checked="false"
                      :options="
                        options && options['boolean'] ? options['boolean'] : []
                      "
                      @change="handleApprovalAudit"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="12" class="mb-3">
                <CRow class="row">
                  <div class="col-lg-12 col-md-12 d-flex pb-2">
                    <div>
                      <label class="text-nowrap pr-3">Comment</label>
                    </div>
                    <div class="w-100" v-if="options['commentsOptions'].length">
                      <Select
                        name="customComments"
                        :value="approval_audit.customComments"
                        @input="handleChangeSelectCustom"
                        :options="
                          options && options['commentsOptions']
                            ? options['commentsOptions']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider v-slot="{ errors }">
                      <TextareaInput
                        name="publish_request_comments"
                        :value="approval_audit.publish_request_comments"
                        @input="handleApprovalAudit"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex justify-content-between py-3 flex-wrap">
          <div>
            <button
              name="cancel"
              class="rounded-0 px-5 btn-lg m-1 mt-3 float-left btn-outline-primary btn-lg mr-2"
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              v-if="isSendForApprovalRequired"
              name="publish_page"
              class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
              @click="onPublish()"
              :disabled="!approval_audit.publish_request_comments"
            >
              {{ latestApprovalAudit ? "Republish Job" : "Publish Job" }}
            </button>
            <button
              v-else
              name="publish_page"
              class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
              @click="onPublish()"
            >
              Publish Job
            </button>
          </div>
        </div>
      </template>
    </CModal>

    <CModal
      id="warningModel"
      title="NOTIFICATION"
      :color="warningModal.color"
      centered:false
      :show.sync="warningModal.isShowPopup"
    >
      <h6 class="text-center">{{ warningModal.content }}</h6>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <div v-if="!warningModal.on_click">
            <button
              @click="warningModal.isShowPopup = false"
              class="btn btn-danger"
            >
              Ok
            </button>
          </div>
          <div v-else>
            <button @click="clear()" class="btn btn-secondary mr-2">
              Cancel
            </button>
            <button
              v-if="warningModal.on_click == 'goToEditJob'"
              @click="goToEditJob()"
              class="btn"
              :class="`btn-${warningModal.color}`"
            >
              Confirm
            </button>
            <button
              v-if="warningModal.on_click == 'onShowCloneJob'"
              @click="onShowCloneJob()"
              class="btn"
              :class="`btn-${warningModal.color}`"
            >
              Confirm
            </button>
            <button
              v-if="warningModal.on_click == 'onUpdateJobStatus'"
              @click="onUpdateJobStatus()"
              class="btn"
              :class="`btn-${warningModal.color}`"
            >
              Confirm
            </button>
            <button
              v-if="warningModal.on_click == 'moveJobToDraft'"
              @click="moveJobToDraft()"
              class="btn"
              :class="`btn-${warningModal.color}`"
            >
              Confirm
            </button>
          </div>
        </footer>
      </template>
    </CModal>
    <job-approval-comments
      :isShowPopup="jobApprovalComments.isShowPopup"
      :approval_audits="jobApprovalComments.approval_audits"
      :popupModalCallBack="jobApprovalCommentsCallBack"
    />
    <PreviewPreScreening
      v-if="previewPreScreeningModal.isShowPopup"
      :isShowPopup="previewPreScreeningModal.isShowPopup"
      :jobInfo="previewPreScreeningModal.jobInfo"
      :preScreeningQuestions="previewPreScreeningModal.preScreeningQuestions"
      :modalTitle="previewPreScreeningModal.modalTitle"
      @modalCallBack="previewPreScreeningModalCallBack"
    />
    <Modal
      v-if="TFWPublishModal.isShowPopup"
      :modalTitle="TFWPublishModal.title"
      :modalColor="TFWPublishModal.color"
      :modalContent="TFWPublishModal.content"
      :isShowPopup="TFWPublishModal.isShowPopup"
      :buttons="TFWPublishModal.buttons"
      :modalCallBack="TFWPublishModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import m from "moment";
import { mapGetters, mapActions } from "vuex";
import jobDetail from "@/components/JobBoardDetail/jobDetail.vue";
import { Role, getScope, CONSTANT as CONST } from "@/helpers/helper";
import JobPreview from "@/components/JobDetailPreview/JobDetailPreview.vue";
import Modal from "@/components/reusable/Modal";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import Select from "@/components/reusable/Fields/Select.vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import JobApprovalComments from "./JobApprovalComments.vue";
import isJobSupplier from "@/mixins/isJobSupplier";
import PreviewPreScreening from "@/components/reusable/PreviewPreScreening";
import RadioButton from "@/components/reusable/Fields/RadioButton";

export default {
  extends: jobDetail,
  mixins: [isJobSupplier],
  components: {
    JobPreview,
    Modal,
    DatePicker,
    Select,
    TextareaInput,
    JobApprovalComments,
    PreviewPreScreening,
    RadioButton,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      Roles: Object.values(Role),
      publishModal: false,
      sharing: {
        url: "",
        title: "",
        description:
          "Hi, I've sent you a link to a job that might be of interest to you. Please click the link above.",
        quote:
          "Hi, I've sent you a link to a job that might be of interest to you. Please click the link below.",
        hashtags: "talentfind",
        twitterUser: "youyuxi",
      },
      updateJobId: null,
      onChangeJobStatus: "",
      approval_audit: {},
      jobApprovalComments: {
        isShowPopup: false,
        approval_audits: {},
      },
      warningModal: {
        color: "primary",
        isShowPopup: false,
        content: "",
        on_click: "",
      },
      TFWPublishModal: {
        isShowPopup: false,
        color: "primary",
        content:
          "Do you want to publish this job in Talentfind Marketplace Job Board?",
        title: "Confirmation",
        buttons: ["No", "Yes"],
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getSelectedJobBenefitsAndAdvertDesc",
      "actionComments",
      "getUserId",
      "jobListBackUrl",
      "has_relationship_with_super_hospital",
      "getOrgTypeIDFromAccessToken",
    ]),
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        commentsOptions: this.actionComments || [],
      };
    },
    isCandidateOrPublicBoard() {
      return (
        !this.getRoleScopes.length ||
        ["system_candidate", "customer_candidate"].includes(
          this.getRoleScopes[0]
        )
      );
    },
    latestApprovalAudit() {
      return (
        this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits?.filter(
          (val) => !val.completed_by
        )[0] || null
      );
    },
    isAwaitingForApproval() {
      return (
        this.latestApprovalAudit?.status_id == CONST.JOB_PENDING_STATUS &&
        this.latestApprovalAudit.sub_status_id ==
          CONST.JOB_AWAITING_APPROVAL_STATUS
      );
    },
    isOnHoldOrDisapproved() {
      return (
        this.latestApprovalAudit?.status_id == CONST.JOB_PENDING_STATUS &&
        (this.latestApprovalAudit.sub_status_id == CONST.JOB_ON_HOLD_STATUS ||
          this.latestApprovalAudit.sub_status_id ==
            CONST.JOB_DISAPPROVED_STATUS)
      );
    },
    getPendingStatus() {
      if (this.latestApprovalAudit?.status_id == CONST.JOB_PENDING_STATUS) {
        if (
          this.latestApprovalAudit?.sub_status_id ==
          CONST.JOB_AWAITING_APPROVAL_STATUS
        )
          return {
            class: "awaiting",
            content: this.latestApprovalAudit?.sub_status,
          };
        if (this.latestApprovalAudit?.sub_status_id == CONST.JOB_ON_HOLD_STATUS)
          return {
            class: "onhold",
            content: this.latestApprovalAudit?.sub_status,
          };
        if (
          this.latestApprovalAudit?.sub_status_id ==
          CONST.JOB_DISAPPROVED_STATUS
        )
          return {
            class: "disapproved",
            content: this.latestApprovalAudit?.sub_status,
          };
      }
      return false;
    },
    _is_last_action_is_published() {
      return (
        this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits?.[-1]
          ?.status_id == CONST.JOB_ACTIVE_STATUS
      );
    },
    job_authorizer_ids() {
      return (
        this.getSelectedJobBoard.job_users
          ?.filter((val) => {
            return val?.customer_user?.customer_user_type_id == 4;
          })
          ?.map((val) => val.customer_user?.user_id) || []
      );
    },
    isSendForApprovalRequired() {
      if (this.isJobSupplier || this.job_authorizer_ids?.length) {
        if (
          (this._is_last_action_is_published &&
            !this.getSelectedJobBoard.approval_required) ||
          this.job_authorizer_ids.includes(this.getUserId)
        ) {
          return false;
        }
      }
      return true;
    },
    date_created() {
      const dateField =
        this.getSelectedJobBoard?.published_on ||
        this.getSelectedJobBoard?.created_on;
      return this.formatDate(dateField);
    },
    expiryDate() {
      return this.formatDate(this.getSelectedJobBoard?.expiry_review_date);
    },
    expiryDays() {
      let days = 0;
      if (this.getSelectedJobBoard.expiry_review_date) {
        let diff = m(this.getSelectedJobBoard.expiry_review_date).diff(
          m(),
          "days"
        );
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    parent_organisation() {
      let job = this.getSelectedJobBoard;
      return job?.organisation?.parent_organisation?.[0];
    },
    fileSource() {
      let job = this.getSelectedJobBoard;
      if (
        this.isTFWPublicJobBoard &&
        job?.organisation?.organisation_type_id === partner_hos_org_type_id &&
        this.parent_organisation
      ) {
        return {
          logo: this.parent_organisation?.logo_url || "",
          image: this.parent_organisation?.default_job_banner_url || "",
        };
      }
      if (
        this.isParentReBranding &&
        this.isCandidateOrPublicBoard &&
        job?.parent_org_info?.length
      ) {
        return {
          logo: job?.parent_org_info[0]?.logo_url || "",
          image: job?.parent_org_info[0]?.default_job_banner_url || "",
        };
      }
      return {
        logo: job?.organisation?.logo_url || "",
        image: this.jobPicture
          ? window.location.origin + this.jobPicture
          : job?.organisation?.default_job_banner_url || "",
      };
    },
    url() {
      return `https://${window.location.host}/public/job-board/${this.getSelectedJobBoard?.customer_uid}?job_id=${this.getSelectedJobBoard?.job_id}`;
    },
    shareTitle() {
      return `Job Recommendation From: ${this.getUser.first_name?.replace(
        /^./,
        this.getUser.first_name[0].toUpperCase()
      )}`;
    },
    isShowTFWPublish() {
      const isAgency =
        this.has_relationship_with_super_hospital &&
        this.getOrgTypeIDFromAccessToken === 3;
      const hasSuperHospitalRel =
        this.getSelectedJobBenefitsAndAdvertDesc?.customer_organisation?.customer_organisation_relationship?.some(
          ({ host_is_super_hospital }) => host_is_super_hospital
        ) || false;
      return isAgency || hasSuperHospitalRel || false;
    },
    publish_in_super_hospital() {
      return this.getSelectedJobBoard?.publish_in_super_hospital || false;
    },
    isPublished() {
      return this.getSelectedJobBoard?.published || false;
    },
    isTFWPublicJobBoard() {
      const { name } = this.$route;
      return ["TFW Job Board"].includes(name) || false;
    },
    displayLocation(){
      return this.getSelectedJobBoard?.display_location;
    }
  },
  mounted() {
    // this.scrollTop();
    this.fetchCommentsfor_jobapproval();
  },
  methods: {
    ...mapActions([
      "modifyPublishJob",
      "onChangeActiveJobsStatus",
      "cloneJob",
      "scrollTop",
      "fetchCommentsfor_jobapproval",
      "updateJobByDetailPage",
    ]),
    openTFWModal() {
      this.TFWPublishModal.isShowPopup = true;
    },
    TFWPublishModalCallBack(action) {
      if (action === "Yes") {
        const { job_id, customer_uid, organisation_uid } =
          this.getSelectedJobBoard;
        const payload = {
          job_id,
          customer_uid,
          organisation_id: organisation_uid,
          publish_in_super_hospital: true,
        };
        this.updateJobByDetailPage(payload).then((res) => {
          if (res?.status === 200) {
            const { data } = res;
            this.$store.commit("SELECTED_JOBBOARD", data);
          }
        });
      }
      this.TFWPublishModal.isShowPopup = false;
    },
    handleChangeSelectCustom(name, value) {
      const comment = "publish_request_comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.approval_audit, comment, v);
      Vue.set(this.approval_audit, name, v);
      this.payload = {
        ...this.payload,
        [comment]: v,
      };
    },
    handleApprovalAudit(name, value) {
      Vue.set(this.approval_audit, name, value);
    },
    async onPublish() {
      let payload = {
        organisation_uid: this.getSelectedJobBoard?.organisation_uid,
        job_id: this.getSelectedJobBoard?.job_id,
        published: true,
      };
      if (
        this.isSendForApprovalRequired &&
        !this.getSelectedJobBoard.is_private_job
      ) {
        const isValid = await this.$refs.action_modal.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        }
        payload = {
          ...payload,
          publish_request_is_urgent:
            this.approval_audit?.publish_request_is_urgent?.code || false,
          publish_request_comments:
            this.approval_audit?.publish_request_comments,
          publish_request_expiry_date: m(
            this.approval_audit?.publish_request_expiry_date
          )
            .add(5.5, "hours")
            .toISOString(),
        };
      }
      if (
        m(this.getSelectedJobBoard?.expiry_review_date, "YYYY-MM-DD").isBefore(
          m()
        )
      )
        payload = {
          ...payload,
          expiry_review_date: m().add(30, "days").format("YYYY-MM-DD"),
        };
      this.modifyPublishJob(payload).then(() => {
        this.onCancelPublish();
        if (this.$router.currentRoute.params.jobStatus == "inactive") {
          this.$store.commit(
            "UPDATE_JOB_BOARD_BY_REMOVING_JOB",
            this.getSelectedJobBoard?.job_id
          );
        }
      });
    },
    onCancelPublish() {
      this.publishModal = false;
    },
    onEditJob() {
      this.warningModal.content = "Confirm you wish to Edit this job?";
      this.warningModal.on_click = "goToEditJob";
      this.warningModal.isShowPopup = true;
    },
    makeJobDraft() {
      this.updateJobId = this.getSelectedJobBoard?.job_id;
      this.warningModal.content = "Confirm you wish to Draft this job?";
      this.warningModal.on_click = "moveJobToDraft";
      this.warningModal.isShowPopup = true;
    },
    goToEditJob() {
      this.clear();
      this.$router.push({
        path: `/edit-job/${this.getSelectedJobBoard?.job_id}?step=0`,
      });
    },
    moveJobToDraft() {
      if (this.updateJobId) {
        this.modifyPublishJob({
          customer_id: this.getSelectedJobBoard?.customer_uid,
          organisation_uid: this.getSelectedJobBoard?.organisation_uid,
          job_id: this.updateJobId,
          status_id: CONST.JOB_DRAFT_STATUS,
        }).then(() => {
          if (this.$router.currentRoute.params.jobStatus == "inactive") {
            this.$store.commit(
              "UPDATE_JOB_BOARD_BY_REMOVING_JOB",
              this.getSelectedJobBoard?.job_id
            );
          }
        });
        this.updateJobId = null;
      }
      this.clear();
    },
    clear() {
      this.warningModal.isShowPopup = false;
      setTimeout(() => {
        this.warningModal.color = "danger";
        this.warningModal.on_click = "";
      }, 100);
    },
    updateJobStatus(status) {
      this.updateJobId = this.getSelectedJobBoard?.job_id;
      this.onChangeJobStatus = status;
      this.warningModal.content = `Are you sure you want to
        ${this.onChangeJobStatus == "inactive" ? "deactivate" : "archive"} this
        job?`;
      this.warningModal.on_click = "onUpdateJobStatus";
      this.warningModal.isShowPopup = true;
    },
    onCloneJob() {
      this.tooltipHide();
      this.updateJobId = this.getSelectedJobBoard?.job_id;
      this.warningModal.content = "Cloning allows you to quickly create jobs. Click confirm and then edit your job to customise as necessary";
      this.warningModal.on_click = "onShowCloneJob";
      this.warningModal.isShowPopup = true;
    },
    onUpdateJobStatus() {
      if (this.updateJobId) {
        this.onChangeActiveJobsStatus({
          job_id: this.updateJobId,
          status: this.onChangeJobStatus,
        }).then(() => {
          let route = this.isInActiveJob ? "inactive" : "active";
          this.$router.push(`/jobs-list/status/${route}`);
        });
        this.onChangeJobStatus = "";
        this.updateJobId = null;
      }
      this.clear();
    },
    onShowCloneJob() {
      if (this.updateJobId) {
        this.cloneJob({
          job_id: this.updateJobId,
        });
        this.updateJobId = null;
      }
      this.clear();
    },
    publishJob(entity) {
      if (entity === "publish") {
        if (this.isAwaitingForApproval) {
          this.warningModal.content = "Job has been sent for approval already.";
          this.warningModal.isShowPopup = true;
          return;
        }
        if (this.isSendForApprovalRequired) {
          this.$refs.action_modal?.errors.clear;
          this.$refs.action_modal?.reset();
          let audits = [];
          if (
            this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits?.length
          ) {
            audits = this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits
              ?.sort(
                (a, b) =>
                  new Date(b.initiated_date) - new Date(a.initiated_date)
              )
              .filter(
                (val) => val.status_id == 28 && val.sub_status_id == 72
              )[0];
            if (m(audits?.expiry_date, "YYYY-MM-DD").isBefore(m()))
              audits.expiry_date = m().add(5, "days");
          }
          this.approval_audit = {
            publish_request_comments: "",
            publish_request_is_urgent: audits?.is_urgent
              ? audits?.is_urgent
              : false,
            publish_request_expiry_date: audits?.expiry_date
              ? new Date(audits?.expiry_date)
              : new Date(m().add(5, "days")),
          };
        }
        this.publishModal = true;
      } else if (entity == "direct_publish") {
        this.onPublish();
      }
    },
    showApprovalComments() {
      this.tooltipHide();
      this.jobApprovalComments = {
        approval_audits:
          this.getSelectedJobBenefitsAndAdvertDesc?.approval_audits,
        isShowPopup: true,
      };
    },
    jobApprovalCommentsCallBack() {
      this.jobApprovalComments.isShowPopup = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    formatDate(date) {
      return date ? m(date).format("DD MMM YYYY") : "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300;
}
.img-wrapper {
  overflow: hidden;
  background: url(../../../public/img/job-image.jpg) no-repeat 50% 50%;
  background-size: cover;
}
.f-1_5 {
  font-size: 1.5rem;
}
.btn:disabled {
  cursor: not-allowed;
}
.status-awaiting {
  background-color: green;
  border: 1px solid green;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-onhold {
  background-color: orange;
  border: 1px solid orange;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-disapproved {
  background-color: red;
  border: 1px solid red;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.ratio-1 {
  aspect-ratio: 1;
}
.parent-info {
  position: relative;
  left: 4.8rem;
  top: 1.05rem;
}
.parent-branding-dot {
  height: 10px;
  width: 10px;
  background-color: $font-theme-color;
  border-radius: 60%;
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: -5px;
  right: 100px;
}
.archive-button {
  width: 140px;
  height: 32px;
  text-align: center;
  font-size: 12px;
}
</style>
